// init
import axios from "axios";
import services from '../data.services/data-services-main.json'
import { encrypt, decrypt } from "./srvc-encr-node";

const base = (services.data.find(x => x.code == 'assets')).link

// -----------------

export const AssetUnitCreate = async (item) => {
  
  const basx = base + '/units/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item.client
  }

  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)


  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const AssetUnitDetails = async (item) => {
  
  const basx = base + '/units/details';
  const head = { 
    "Content-Type": "application/json",
          "Authorization": item.client

  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)


  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const AssetEdit = async (item) => {
  
  const basx = base + '/assets/edit';
  const head = { 
    "Content-Type": "application/json",
          "Authorization": item.client

  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)


  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const unitsMint = async (item) => {
  
  const basx = base + '/units/status/mint/set';
  const head = { 
    "Content-Type": "application/json",
          "Authorization": item.client

  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)


  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const unitsLive = async (item) => {
  
  const basx = base + '/units/status/live/set';
  const head = { 
    "Content-Type": "application/json",
          "Authorization": item.client

  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)


  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const unitsScan = async (item) => {
  
  const basx = base + '/units/status/scan/set';
  const head = { 
    "Content-Type": "application/json",
          "Authorization": item.client

  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)


  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const unitsBook = async (item) => {
  
  const basx = base + '/units/status/book/set';
  const head = { 
    "Content-Type": "application/json",
          "Authorization": item.client

  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)


  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const unitsShow = async (item) => {
  
  const basx = base + '/assets/status/discover/set';
  const head = { 
    "Content-Type": "application/json",
          "Authorization": item.client

  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)


  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}



export const AssetUnitDataMintSet = async (item) => {
  
  const basx = base + '/units/data/mint/set';
  const head = { 
    "Content-Type": "application/json",
          "Authorization": item.client

  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)


  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const AssetUnitDataBookSet = async (item) => {
  
  const basx = base + '/units/data/book/set';
  const head = { 
    "Content-Type": "application/json",
          "Authorization": item.client

  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)


  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const AssetUnitDataSaleSet = async (item) => {
  
  const basx = base + '/units/data/sale/set';
  const head = { 
    "Content-Type": "application/json",
          "Authorization": item.client

  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)


  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const AssetUnitRateSet = async (item) => {
  
  const basx = base + '/units/rate/set';
  const head = { 
    "Content-Type": "application/json",
          "Authorization": item.client

  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)


  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const AssetUnitListMembers = async (item) => {
  
  const basx = base + '/units/list/members';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item.client
  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)


  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}