// auth - firebase mail link
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NumberFormat } from "../../services/srvc-utilities";
import { Link } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";

import NavsButtonBack from "../webx/navs-button-back";
import NavsButtonNext from "../webx/navs-button-next";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import { TransfersFundList } from "../../services/srvc-transfers-funds-realm";
import { TransfersAssetList } from "../../services/srvc-transfers-assets-realm";
import { useSelector, useDispatch } from "react-redux";
import { updatePage } from "../actions/transferActions";
import { DispalyLoder } from "../common/utils";

const list = require("../../data.static/data-transfers-status.json").data;

export default function TransfersListModule() {
  // console.log(props.search)
  const dispatch = useDispatch();
  const transferData = useSelector((state) => state.transfer);
  // console.log(transferData);

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);

  const [search, setSearch] = useState();

  const [items, setItems] = useState(10);

  const curr = useSelector((state) => parseInt(state.transfer.page, 10)); // Get currentPage from Redux store and parse it to integer
  const index = useSelector((state) => parseInt(state.transfer.page, 10)); // Get currentPage from Redux store and parse it to integer

  const [next, setNextIndex] = useState();
  const [last, setLastIndex] = useState();

  const [count, setCount] = useState();
  const [total, setTotal] = useState();

  const [text, setText] = useState("");

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        const resultAssets = []
        // await TransfersAssetList({
        //   data: {
        //     creator: asset.item,
        //     index: curr,
        //     items: items,
        //     filters: {
        //       name: transferData.success
        //         ? "success"
        //         : transferData.pending
        //         ? "pending"
        //         : transferData.failed
        //         ? "failed"
        //         : "",
        //     },
        //     search: {
        //       toDate: transferData.end,
        //       fromDate: transferData.start,
        //     },
        //   },
        //   client: asset?.creds?.client,
        //   service: asset?.creds?.service,
        // });

        const resultFunds = await TransfersFundList({
          data: {
            user: asset.item,
            index: curr.toString(),
            items: items.toString(),
            filters: {
              name: transferData.success
                ? "success"
                : transferData.pending
                ? "pending"
                : transferData.failed
                ? "failed"
                : "",
            },
            search: {
              toDate: transferData.start,
              fromDate: transferData.end,
            },
          },
          service: asset?.creds?.service,
          client:asset?.creds?.client,
          secret:asset?.creds?.secret
        });

        // console.log(resultFunds);

        if (resultAssets?.stat || resultFunds.stat) {
          const datx = [
            // ...Array.from(resultAssets?.data?.list, (x) => {
            //   return {
            //     ...x,
            //     amount: x.size,
            //   };
            // }),

            ...Array.from(resultFunds.data.list, (x) => {
              return {
                ...x,
                amount: x.sale,
              };
            }),
          ];
          setData(datx.sort((a, b) => b.created - a.created));

          const resTotal = resultAssets?.data?.count || 0 + resultFunds.data.count;
          setTotal(resultAssets?.data?.count || 0 + resultFunds.data.count);

          setText(
            `${(index - 1) * items + 1} - ${
              index * items < resTotal ? index * items : resTotal
            } of ${resTotal}`
          );
        }

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, [
    transferData.success,
    transferData.end,
    transferData.start,
    transferData.failed,
    transferData.pending,
    transferData.end,
    index,
    items,
  ]);

  const NextIndex = async () => {
    if (data.length < items) {
      dispatch(updatePage(curr)); // Dispatch action to update page
    } else {
      dispatch(updatePage(curr+1)); // Dispatch action to update page
    }
  };

  const LastIndex = async () => {
    if (index == 1) {
      dispatch(updatePage(curr)); // Dispatch action to update page

    } else {
     dispatch(updatePage(curr-1)); // Dispatch action to update page
    }

  };

  // console.log(data)



  if (loader) return <DispalyLoder />;
  if (!loader && (!data || data.length === 0))
    return <DispalyLoder msg={"No Transfers"} />;

  return (
    <>
      <div className={loader ? "d-none" : "back-color-wite border rounded"}>
        {/* header */}
        <div className="border-bottom text-small text-bold">
          <div className="d-flex p-2 px-3">
            <div className="" style={{ width: "5%" }}>
              <p className="m-0">
                <span className="text-small">
                  <i className="bx bxs-grid-alt text-color-tint"></i>
                </span>
              </p>
            </div>

            <div className="" style={{ minWidth: "55%" }}>
              <p className="m-0">
                <span className="">{"Details"}</span>
              </p>
            </div>

            <div className="" style={{ width: "20%" }}>
              <p className="m-0 text-sm">
                <span className="">{"Status"}</span>
              </p>
            </div>

            <div className="ms-auto text-end text-sm" style={{ width: "20%" }}>
              <p className="m-0 text-sm ">
                <span className="d-none d-md-block">{"Amount"}</span>
                <span className="d-md-none">{"Status"}</span>
              </p>
            </div>
          </div>
        </div>

        {/* data */}
        {data &&
          data.map((item, i) => (

            <Link
            to={`/team/tx/${item?.feat?.form}/${item?.item}`}
            style={{textDecoration:"none" , color:"black"}}
          >
            <div key={i}>
             
                <div className="d-flex p-2 px-3">
                  <div className="" style={{ width: "5%"}}>
                    <p className="m-0">
                      <span className="text-normal">
                        <i
                          className={`bx bx-image text-color-tone ${
                            item.feat.form == "assets" ? "" : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bx bx-credit-card text-color-tone ${
                            item.feat.form == "funds" ? "" : "d-none"
                          }`}
                        ></i>
                      </span>
                    </p>
                  </div>

                  <div className="" style={{ width: "55%" }}>
                    <p className="m-0">
                      <span className="text-bold">{item.user.name}</span>
                    </p>
                    <p className="m-0 text-small text-sm">
                      {item?.meta?.memo || "******"}
                    </p>
                    <p className="m-0 text-small">
                      <span>
                        {new Date(parseInt(item?.created)).toLocaleString() ||
                          "******"}
                      </span>
                    </p>
                  </div>
                  <div className="" style={{ width: "20%" }}>
                    <span
                      className={`p-2 rounded-wd text-mini text-uppercase text-color-wite back-${
                        list.find((x) => x.code == item.status).color
                      }`}
                      style={{ fontSize: "0.6rem" }}
                    >
                      {list.find((x) => x.code == item.status).memo}
                    </span>
                  </div>
                  <div className="ms-auto text-end" style={{ width: "20%" }}>
                    <p className="m-0">
                      <span className="ms-1">
                        {item.mode == "credit" ? "+" : "-"}
                      </span>
                      <span className="">{item?.amount?.number || "0"}</span>
                      <span className="ms-1"></span>
                      <span className="text-mini">
                        {item?.amount?.ticker || ""}
                      </span>
                    </p>
                  </div>
                </div>
              <div className={i < data.length - 1 ? "border-bottom" : ""}></div>
            </div>
            </Link>

          ))}
      </div>

      {/* navs */}
      <div className={loader ? "d-none" : ""}>
        <WebbDividerSmall />
        <div className={data.length < items ? "" : ""}>
          <div className="d-flex justify-content-between">
            <div className="" onClick={() => LastIndex()}>
              <NavsButtonBack />
            </div>

            <div className="">
              <p className="my-3">{text}</p>
            </div>

            <div className="" onClick={() => NextIndex()}>
              <NavsButtonNext />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
