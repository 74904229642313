// account
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoaderMedium from "../webx/webb-loader-md";
import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbModuleInfo from "../webx/webb-module-info";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import { TeamContactCount } from "../../services/srvc-users-creators-realm";
import { CredentialMemberCount } from "../../services/srvc-credentials-realm";
import { AssetStatisticsCreator } from "../../services/srvc-assets-realm";


const list = [
  {name: 'Events', memo: '', icon: 'bx bx-calendar-event', number: '', ticker: '***', rate: '', active: true},
  {name: 'Events (active)', memo: '', icon: 'bx bx-calendar-check', number: '', ticker: '***', rate: '', active: true},
  {name: 'Events (upcoming)', memo: '', icon: 'bx bx-calendar', number: '', ticker: '***', rate: '', active: true},
  {name: 'Events (over)', memo: '', icon: 'bx bx-calendar', number: '', ticker: '***', rate: '', active: true},
  {name: 'Activity', memo: '', icon: 'bx bx-play-circle', number: '', ticker: '***', rate: '', active: false},
  {name: '', memo: '', icon: 'bx bx-user-circle', number: '', ticker: '***', rate: '', active: false},
  {name: '', memo: '', icon: 'bx bx-user-circle', number: '', ticker: '***', rate: '', active: false}
]

export default function TeamEventStatisticsModule () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState()

  // console.log(listactions.filter(x => x.user.includes(asset.role)))

  useEffect( () => {
    if (asset){

      const fetchData = async() => {
        setLoader(true);

        const assetx = await AssetStatisticsCreator({data: {creator: asset.item}, service: asset?.creds?.service,client:asset?.creds?.client,secret:asset?.creds?.secret })
        // console.log (assetx,"assetx")

        var statx = list.filter(x => x.active)
        // console.log(statx)
        statx[0] = {...statx[0], number: assetx.data.total, rate: (Math.random()*10).toFixed(0)}
        statx[1] = {...statx[1], number: assetx.data.active, rate: (Math.random()*10).toFixed(0)}
        statx[2] = {...statx[2], number: assetx.data.upcoming, rate: (Math.random()*10).toFixed(0)}
        statx[3] = {...statx[3], number: assetx.data.over, rate: (Math.random()*10).toFixed(0)}


        setData(statx)
        // console.log(statx)

        setLoader(false);
      }
      fetchData()
    } else {}
  },[]);


  if (loader)
  return (
    <div className="text-center">
      <div className="spinner-border text-secondary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );

  return (
  <>
    
    <div className="">

      <div className="row row-cols-4 row-cols-md-4 g-2">
        {data && data.map((item, i) => (item.active ?
        <div className="col" key={i}>

          <div className={`back-color-wite rounded p-3 w-100 h-100 border m-0 p-0`}>
            
            <div className="d-flex">
              <div className="">
                <p className="text-small m-0">{item.name}</p>
                <p className="text-header-sm m-0">{item.number || '0'}</p>
              </div>
              <div className="ms-auto ">
                <i className={`text-icon-md text-color-tint ${item.icon}`}></i>
              </div>
            </div>
            
            <div className="mb-1 d-none"></div>

            <p className="m-0 d-none">
              <span><i className="bx bx-up-arrow-alt text-color-success"></i></span>
              <span>{item.rate || '0'}{'%'}</span>
            </p>

          </div>

        </div>
        :''))}

      </div>
    </div>
  </>

  )
}