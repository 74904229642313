// assets
import { useEffect, useState } from 'react'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'


const listData = [
  { name: 'Total', code: 'count', number: '0', ticker: '', active: false },
  { name: 'Mint', code: 'mint', number: '0', ticker: '', active: true },
  { name: 'Book', code: 'book', number: '0', ticker: '', active: true },
  { name: 'Sale', code: 'sale', number: '0', ticker: '', active: true },
  { name: 'Revoke', code: 'burn', number: '0', ticker: '', active: true }
]

export default function UnitStatisticsModule (props) {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx


  const [loader, setLoader] = useState(true)
  const [data, setData] = useState(listData.filter(z=>z.active))


  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        
        setLoader(true)
        var datx = listData.filter(z=>z.active)
        datx = Array.from(datx, x => { return {...x, number: props.data[x.code]}})
        setData(datx)

        setLoader(false)
      }
      fetchData()
    } else {}
  }, [props])

  const handleClick = async item => {
    // navigate(`/${asset.role}/ax/${item}`)
  }

  return (
    <>

      {/* asset units */}
      <div className='back-color-wite rounded p-3 border'>
        <div className='row row-cols-2 row-cols-md-4 g-3'>
          {data && data.map((item, i) => item.active ? (
            <div className='col' key={i}>
              <div className={`w-100 h-100 border-none m-0 p-0`}>
                <div className='d-flex'>
                  <div className=''>
                    <p className='text-mini m-0 text-uppercase'>{item.name}</p>
                    <p className='text-lead text-bold m-0'> {item.number || '0'}</p>
                  </div>
                  <div className='ms-auto '>
                    <i className={`text-icon-md text-color-tint ${item.icon}`} ></i>
                  </div>
                </div>
              </div>
            </div>
          ) 
          : (''))}
        </div>
      </div>
    </>
  )
}
