// network
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import { NumberFormat } from "../../services/srvc-utilities";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";

import NavsButtonBack from "../webx/navs-button-back";
import NavsButtonNext from "../webx/navs-button-next";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness, GetAuthUser } from "../../services/srvc-auth-local";

import {
  TeamMemberDelete,
  TeamMemberList,
} from "../../services/srvc-users-creators-realm";

export default function TeamListModule(props) {
  // console.log(props.search)
  const auth =GetAuthUser()

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState();

  const [search, setSearch] = useState();

  const [index, setIndex] = useState(1);
  const [items, setItems] = useState(10);

  const [curr, setCurrentIndex] = useState(1);
  const [next, setNextIndex] = useState();
  const [last, setLastIndex] = useState();

  const [count, setCount] = useState();
  const [total, setTotal] = useState();

  const [text, setText] = useState("");

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedMemberToDelete, setSelectedMemberToDelete] = useState(null);
  const [refresh, setRefresh] = useState(true);
  const [ownerEmail, setOwnerEmail] = useState();


  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);
        // console.log ('transfers')

        const result = await TeamMemberList({
          data: {
            team: asset.item,
            index: index,
            items: items,
            filters: { name: props.search },
          },
          service: asset?.creds?.service,
          client:asset?.creds?.client,
          secret:asset?.creds?.secret
        });

        if (result.stat) {
          setData(result.data.list);
          let email = result.data.list.filter((obj) => obj.role === "owner");
          setOwnerEmail(email);

          const count = result.data.count;

          setTotal(count);

          setText(
            `${(index - 1) * items + 1} - ${
              index * items < count ? index * items : count
            } of ${count}`
          );
        }
        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, [props.search, index, items, refresh]);

  const NextIndex = async () => {
    if (data.length < items) {
    } else {
      setNextIndex(curr + 1);
      setIndex(curr + 1);
      setCurrentIndex(curr + 1);
    }
  };

  const LastIndex = async () => {
    if (index == 1) {
    } else {
      setLastIndex(curr - 1);
      setIndex(curr - 1);
      setCurrentIndex(curr - 1);
    }
  };

  const handleClick = async (item) => {
    setSelectedMemberToDelete(item); // store the selected asset to delete
    setShowDeletePopup(true); // Op
  };

  const deleteData = async () => {
    var res = await TeamMemberDelete({
      data: {
        item: selectedMemberToDelete,
      },
      service: asset?.creds?.service,
      client:asset?.creds?.client,
      secret:asset?.creds?.secret
    });
    alert("Member removed.....");
    setShowDeletePopup(false);
    setRefresh(!refresh);
  };

  const confirmDelete = () => {
    deleteData();
  };

  const cancelDelete = () => {
    setShowDeletePopup(false);
  };

  if (loader)
    return (
      <>
        <div
          className="p-3 back-color-wite rounded-xd border align-middle"
          style={{}}
        >
          <span className="align-middle text-lead">
            <i className="bx bxs-info-circle text-color-wait"></i>
          </span>
          <span className="ms-1 text-color-tone">Please Wait...</span>
        </div>
      </>
    );
  if (!loader && (!data || data.length === 0))
    return (
      <>
        <div
          className="p-3 back-color-wite rounded-xd border align-middle"
          style={{}}
        >
          <span className="align-middle text-lead">
            <i className="bx bxs-info-circle text-color-tint"></i>
          </span>
          <span className="ms-1 text-color-tone">No Team Members</span>
        </div>
      </>
    );

  return (
    <>
      <div className="back-color-wite rounded border">
        {/* header */}
        <div className="border-bottom text-bold">
          <div className="d-flex p-2 px-3">
            <div className="" style={{ width: "50%" }}>
              <p className="m-0 text-sm">
                <span className="">{"User Name"}</span>
              </p>
            </div>

            <div className="d-none d-md-block ms-2">
              <p className="m-0 text-sm">
                <span className="">{"Role"}</span>
              </p>
            </div>

            <div className="ms-auto text-end text-sm">
              <p className="m-0 text-sm">
                <span className="">{"Action"}</span>
              </p>
            </div>
          </div>
        </div>

        {/* data */}
        {data &&
          data.map((item, i) => (
            <div className="" key={i}>
              <div className="d-flex p-2 px-3">
                <div className="mt-1 me-2 d-none">
                  <span className="align-middle">
                    <Jazzicon
                      diameter={30}
                      seed={jsNumberForAddress(
                        item?.item || Date.now().toString()
                      )}
                    />
                  </span>
                </div>

                <div className="d-flex" style={{ width: "50%" }}>
                  <div className="mt-1 me-2">
                    <span className="align-middle">
                      <Jazzicon
                        diameter={30}
                        seed={jsNumberForAddress(
                          item?.item || Date.now().toString()
                        )}
                      />
                    </span>
                  </div>
                  <div className="">
                    <p className="m-0 text-sm">
                      <span className="text-bold">{item.user.name}</span>
                    </p>
                    <p className="m-0 text-small text-sm">
                      <span className="">{item.user.mail}</span>
                    </p>
                  </div>
                </div>

                <div className="d-none d-md-block ms-2">
                  <p className="mt-2 m-0 text-sm">
                    <span className="">{item?.role}</span>
                  </p>
                </div>
                {item.role=="member" &&  (auth?.user ==  ownerEmail[0]?.user?.mail)  && (
                  <div
                    className={`ms-auto text-end text-sm`}
                    onClick={() => handleClick(item.item)}
                  >
                    <p className="p-1 px-2 text-bg-danger rounded text-xs cursor m-0">
                      Remove
                    </p>
                  </div>
                )}
              </div>
              <div className={i < data.length - 1 ? "border-bottom" : ""}></div>
            </div>
          ))}

        {showDeletePopup && (
          <div
            className="popup"
            style={{
              height: "100%",
              width: "100%",
              position: "fixed",
              top: "0px",
              left: "0px",
              backdropFilter: "brightness(0.5)",
            }}
          >
            <div
              className="popup-content p-4 border rounded"
              style={{
                position: "absolute",
                top: "30%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "white",
              }}
            >
              <h3 className=" text-center">Confirm remove member</h3>
              <p>Are you sure you want to remove this member?</p>
              <div className="popup-buttons  d-flex gap-3 justify-content-between">
                <button
                  className="btn btn-primary rounded-xx text-small"
                  onClick={cancelDelete}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-danger rounded-xx text-small"
                  onClick={confirmDelete}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* navs */}
      <WebbDividerSmall />
      <div className={data.length < items ? "mx-3" : "mx-3"}>
        <div className="d-flex justify-content-between">
          <div className="" onClick={() => LastIndex()}>
            <NavsButtonBack />
          </div>

          <div className="">
            <p className="my-3">{text}</p>
          </div>

          <div className="" onClick={() => NextIndex()}>
            <NavsButtonNext />
          </div>
        </div>
      </div>
    </>
  );
}
