// transfers
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";

import { GetUserForm, NumberFormat } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import { TransfersFundAccountCredit } from "../../services/srvc-transfers-funds-realm";
import { AccountsBaseBalance } from "../../services/srvc-accounts-realm";
import {
  orderCreate,
  orderSave,
  orderVerify,
  paymentOrderCreates,
  paymentOrderSave,
  paymentOrderVerify,
} from "../../services/srvc-orders-realm";

export default function TransfersAccountCreditModule() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [form, setForm] = useState(false);
  const [memo, setMemo] = useState("");
  const [balance, setBalance] = useState({
    number: "000000",
    ticker: "******",
  });

  const [data, setData] = useState({
    number: "",
    ticker: "inr",
  });

  const [orderId, setOrderId] = useState("");
  const [order, setOrder] = useState();

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);
        const result = await AccountsBaseBalance({
          data: { user: asset.item },
          service: asset?.creds?.service,
          client: asset?.creds?.client,
          secret: asset?.creds?.secret,
        });

        if (result.stat) {
          setBalance(result.data.balance);
        }

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);

  // useEffect for form validation
  useEffect(() => {
    setForm(false);
    if (data.number !== "" && data.number !== 0 && data.number > 98)
      setForm(true);
  }, [data]);

  const handleSubmit = async () => {
    setLoader(true);
    setSubmit(true);
    setMemo("Please wait... 0/1");

    const datx = {
      credit: {
        name: asset?.name || "******",
        mail: asset?.mail || "******",
        item: asset?.item || "******",
      },
      debit: {
        name: asset?.name || "******",
        mail: asset?.mail || "******",
        item: asset?.item || "******",
      },
      meta: { name: "account.credit", memo: "account.credit" },
      count: { number: data.number.toString(), ticker: data.ticker },
      rate: { number: "1", ticker: "" },
      asset: {},
      unit: {},
    };

    const result = await TransfersFundAccountCredit({
      data: datx,
      service: asset?.creds?.service,
      client: asset?.creds?.client,
      secret: asset?.creds?.secret,
    });
    // console.log(result);

    if (result.stat) {
      setMemo("Please wait... Successs");
      setDone(true);
      setMemo("Successs: Account Credit");
    } else {
      setMemo("Please wait... Failed");
    }

    setLoader(false);
  };

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val });
  };

  const handleAccountCredit = async () => {
    setLoader(true);
    setSubmit(true);
    setMemo("Please wait... 0/1");

    var datx = {
      credit: {
        name: asset.name || "******",
        mail: asset?.mail || "******",
        item: asset?.item || "******",
      },
      debit: {
        name: asset?.name || "******",
        mail: asset?.mail || "******",
        item: asset?.item || "******",
      },
      meta: { name: "account.credit", memo: `account.credit` },
      count: {
        number: data?.number || "0",
        ticker: "INR",
      },
      rate: { number: "1", ticker: "" },
      asset: {},
      unit: {},
    };
    var res = await TransfersFundAccountCredit({
      data: datx,
      service: asset?.creds?.service,
      client: asset?.creds?.client,
      secret: asset?.creds?.secret,
    });

    // 2 Paymetn Gateway Call
    var res = await handleRazorPayPaymentGateway(res.data.item);
    //console.log(res)
  };

  const handleRazorPayPaymentGateway = async (trxn) => {
    const orderCreate = await axios.post(
      "https://ap-south-1.aws.data.mongodb-api.com/app/bharat-transfers-orders-sugga/endpoint/order/create",
      {
        data: { amount: data.number },
      }
    );
    // var orderCreate = await paymentOrderCreates({ data:{amount:data.number},service: asset?.creds?.service,client:asset?.creds?.client,secret:asset?.creds?.secret })

    const dataSave = await axios.post(
      "https://ap-south-1.aws.data.mongodb-api.com/app/bharat-transfers-orders-sugga/endpoint/order/save",
      {
        data: { ...orderCreate.data._rejectionHandler0, stat: 0, trxn: trxn },
      }
    );
    // var dataSave = await paymentOrderSave({ data:{...orderCreate.data._rejectionHandler0, stat:0 , trxn:trxn},service: asset?.creds?.service,client:asset?.creds?.client,secret:asset?.creds?.secret })

    const orderTx = orderCreate.data._rejectionHandler0;
    setOrder(orderTx);
    setOrderId(orderTx.id);
    const options = {
      key: "rzp_test_Vpjr5HSfP3SBgV", // process.env.RAZORPAY_APP_KEY
      amount: orderTx.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: asset?.name,
      description: `Account credit`,
      image: "https://example.com/your_logo",
      order_id: orderTx.id,
      handler: (res) => {
        paymentVerificationSave(res);
        return res;
      },
      prefill: {
        name: asset.name,
        email: asset.mail,
        contact: "9000090000",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#121212",
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();
  };

  const paymentVerificationSave = async (value) => {
    const res = await axios.post(
      "https://ap-south-1.aws.data.mongodb-api.com/app/bharat-transfers-orders-sugga/endpoint/payment/verify",
      { data: value }
    );
    // var res = await paymentOrderVerify({ data:{data:value},service: asset?.creds?.service,client:asset?.creds?.client,secret:asset?.creds?.secret })
    if (res.data.stat) {
      setMemo(
        "Transaction submitted , status of transaction will get automatically update..."
      );
      setTimeout(() => {
        navigate("/team/transfers");
      }, 2000);
    }
  };

  // if (loader) return <></>

  return (
    <>
      {/* info */}

      <div
        className={
          submit
            ? "back-color-wite p-3 rounded-xd"
            : "back-color-wite p-3 rounded-xd"
        }
      >
        <div className="back-color-wite p-3 rounded-xd border">
          <p className="text-small m-0">Account Balance</p>
          <p className="m-0">
            <span className="text-lead text-bold">
              {NumberFormat(balance?.number / 1000000 || "0", "", "2")}
            </span>
            <span className="ms-1"></span>
            <span className="text-small">{balance?.ticker || "***"}</span>
          </p>
        </div>

        <WebbDividerSmall />
        <div className={""}>
          <div className="mb-3">
            <label className="form-label text-small">
              Enter Amount (Min 99){" "}
            </label>
            <input
              type="text"
              className="form-control height-md"
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              pattern="[1-9][0-9]*"
              value={data.number}
              onChange={({ target }) => {
                handleChange(
                  "number",
                  target.validity.valid ? target.value : data.number
                );
              }}
              disabled={loader || submit}
              placeholder=""
            ></input>
          </div>
        </div>

        <div className="">
          <WebbDividerMedium />
          <p className="m-0">{memo}</p>
        </div>
        <div className={loader || (submit && !done) ? "" : "d-none"}>
          <div className="mb-2"></div>
        </div>

        <div className={loader || submit ? "d-none" : ""}>
          <WebbDividerMedium />
          <div className="d-flex justify-content-between">
            <button
              className={`btn btn-light border back-color-wite rounded-xx text-small`}
              type="button"
              disabled={loader || submit}
              onClick={() => {
                navigate(-1);
              }}
            >
              {loader ? "Please Wait..." : "Cancel"}
            </button>

            <button
              className={`btn btn-primary border-none rounded-xx text-small`}
              type="button"
              disabled={!form || loader || submit}
              onClick={() => {
                handleAccountCredit();
              }}
            >
              {loader ? "Please Wait..." : "Continue"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
