// transfers
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";

import { GetUserForm, NumberFormat } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import {
  TransfersFundAccountCredit,
  TransfersFundCreate,
} from "../../services/srvc-transfers-funds-realm";
import { TeamContactList } from "../../services/srvc-users-creators-realm";
import { AccountsBaseBalance } from "../../services/srvc-accounts-realm";

export default function TransfersFundsModule() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [form, setForm] = useState(false);
  const [memo, setMemo] = useState("");

  const [list, setList] = useState([]);
  const [index, setIndex] = useState(1);
  const [items, setItems] = useState(10);
  const [balance, setBalance] = useState({
    number: "000000",
    ticker: "******",
  });
  const [beneficiery, setBeneficiery] = useState("");
  const [remark, setRemark] = useState("funds transfer");
  const [amount, setAmount] = useState("");
  const [balanceText, setBalanceText] = useState(false);

  const [data, setData] = useState({
    number: "",
    ticker: "inr",
  });

  useEffect(() => {
    setForm(false);
    if (data.number !== "" && data.number !== 0 && data.number > 98)
      setForm(true);
  }, [data]);

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);
        const result = await AccountsBaseBalance({
          data: { user: asset.item },
          service: asset?.creds?.service,
          client: asset?.creds?.client,
          secret: asset?.creds?.secret,
        });

        if (result.stat) {
          setBalance(result.data.balance);
        }

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);

      const result = await TeamContactList({
        data: {
          team: asset.item,
          index: index,
          items: items,
        },
        service: asset?.creds?.service,
        client: asset?.creds?.client,
        secret: asset?.creds?.secret,
      });

      if (result.stat) {
        setList(result?.data?.list);
      }
      setLoader(false);
    };

    fetchData();
  }, []);

  const handleSubmit = async () => {
    setLoader(true);
    setSubmit(true);
    setMemo("Please wait... 0/1");

    const datx = {
      credit: {
        name: beneficiery?.name || "******",
        mail: beneficiery?.mail || "******",
        item: beneficiery?.item || "******",
      },
      debit: {
        name: asset?.name || "******",
        mail: asset?.mail || "******",
        item: asset?.item || "******",
      },
      meta: { name: "account.credit", memo: remark },
      count: { number: amount.toString(), ticker: "INR" },
      rate: { number: "1", ticker: "" },
      asset: {},
      unit: {},
    };

    // console.log(datx);

    const result = await TransfersFundCreate({
      data: datx,
      service: asset?.creds?.service,
      client: asset?.creds?.client,
      secret: asset?.creds?.secret,
    });

    if (result.stat) {
      setDone(true);
      alert("Successs: Account Credit");
      navigate(-1);
    } else {
      alert("Failed...");
      navigate(-1);
    }

    setLoader(false);
  };

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val });
  };

  const handleSelectBeneficiery = (item) => {
    setBeneficiery(list.find((user) => user.user.item == item).user);
  };

  // if (loader) return <></>

  return (
    <>
      {/* info */}

      <div
        className={
          submit
            ? "back-color-wite p-3 rounded-xd"
            : "back-color-wite p-3 rounded-xd"
        }
      >
        <WebbDividerSmall />
        <div className={""}>
          <div className="back-color-wite p-3 rounded-xd border">
            <p className="text-small m-0">Account Balance</p>
            <p className="m-0">
              <span className="text-lead text-bold">
                {NumberFormat(balance?.number / 1000000 || "0", "", "2")}
              </span>
              <span className="ms-1"></span>
              <span className="text-small">{balance?.ticker || "***"}</span>
            </p>
          </div>
          <WebbDividerMedium />

          <div className="mb-3">
            <label className="form-label text-small">
              Enter Amount (Min 99 ){" "}
            </label>
            <input
              type="number"
              className="form-control height-md mb-3"
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              pattern="[1-9][0-9]*"
              value={amount}
              onChange={({ target }) => {
                setAmount(target.value);
                if (target.value > parseFloat(balance?.number) / 1000000) {
                  setBalanceText(true);
                }
                if (target.value < parseFloat(balance?.number) / 1000000) {
                  setBalanceText(false);
                }
              }}
              disabled={loader || submit}
              placeholder=""
            ></input>

            {balanceText && (
              <p className="text-danger text-small">Insufficient balance</p>
            )}

            <label className="form-label text-small">Select Beneficiery </label>

            <select
              onChange={(e) => handleSelectBeneficiery(e.target.value)}
              className="form-select mb-3"
            >
              <option className="text-small">Choose</option>
              {list.map((item, index) => (
                <option className="text-small" value={item.user.item}>
                  {item?.user?.name}
                </option>
              ))}
            </select>
            <label className="form-label text-small ">Remarks </label>
            <input
              type="text"
              className="form-control height-md"
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              pattern="[1-9][0-9]*"
              value={remark}
              onChange={({ target }) => {
                setRemark(target.value);
              }}
              disabled={loader || submit}
              placeholder=""
            ></input>
          </div>
        </div>

        <div className="">
          <WebbDividerMedium />
          <p className="m-0">{memo}</p>
        </div>
        <div className={loader || (submit && !done) ? "" : "d-none"}>
          <div className="mb-2"></div>
        </div>

        <div className={loader || submit ? "d-none" : ""}>
          <WebbDividerMedium />
          <div className="d-flex justify-content-between">
            <button
              className={`btn btn-light border back-color-wite rounded-xx text-small`}
              type="button"
              disabled={loader || submit}
              onClick={() => {
                navigate(-1);
              }}
            >
              {loader ? "Please Wait..." : "Cancel"}
            </button>

            <button
              className={`btn btn-primary border-none rounded-xx text-small`}
              type="button"
              disabled={
                loader ||
                submit ||
                amount <= 0 ||
                amount > parseFloat(balance?.number) / 1000000
              }
              onClick={() => {
                handleSubmit();
              }}
            >
              {loader ? "Please Wait..." : "Continue"}
            </button>
          </div>
        </div>

        <WebbDividerSmall />
      </div>
    </>
  );
}
