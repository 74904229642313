// init
import axios from "axios";
import services from '../data.services/data-services-main.json'
import { decrypt, encrypt } from "./srvc-encr-node";

const base = (services.data.find(x => x.code == 'assets')).link

// -----------------

export const AssetCreate = async (item) => {
  
  const basx = base + '/assets/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item.client
  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const AssetDetails = async (item) => {
  
  const basx = base + '/assets/details';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client 
  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const AssetList = async (item) => {
  
  const basx = base + '/assets/list';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client 
  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)


  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const AssetSearch = async (item) => {
  
  const basx = base + '/assets/search';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client 
  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)


  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const AssetListCreator = async (item) => {
  
  const basx = base + '/assets/list/creator';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client 
  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)


  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const AssetListMembers = async (item) => {
  
  const basx = base + '/assets/list/members';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client 
  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)


  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const AssetUnits = async (item) => {
  
  const basx = base + '/assets/units';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client 
  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)


  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data }
  }
}

export const AssetStatisticsCreator = async (item) => {
  
  const basx = base + '/assets/statistics/creator';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client 
  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)


  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data }
  }
}

export const AssetFeatures = async (item) => {
  
  const basx = base + '/assets/features';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client 
  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data }
  }
}


export const AssetDelete = async (item) => {
  
  const basx = base + '/assets/delete';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client 
  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)
  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const AssetRateSet = async (item) => {
  
  const basx = base + '/assets/rate/set';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client 
  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)


  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}