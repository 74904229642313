// main
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import ContentFormat from '../content/webz/content-format-xv'

import WebbIcon from '../content/webx/webb-icon'
import WebbHeader from '../content/webz/webb-header-navs-xv'
import UserAvatar from '../content/webx/user-avatar'

import WebbFooterMobile from '../content/webz/webb-footer-mobile'

import WebbDividerMedium from '../content/webx/webb-divider-md'
import WebbDividerSmall from '../content/webx/webb-divider-sm'
import WebbLoaderSmall from '../content/webx/webb-loader-sm'

import { GetUserForm } from '../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../services/srvc-auth-local'

import NetworkSearchModule from '../content/network/network-search'

import NetworkListModule from '../content/network/network-list'
import NetworkListSmallModule from '../content/network/network-list-sm'

import NetworkActionsModule from '../content/network/network-actions-creator'
import NetworkMemberActionsModule from '../content/network.members/member-actions-creator'
import NetworkViewCreatorModule from '../content/network/network-view-creator'


export default function Network () {

  const metadata = {
    name: 'Network',
    banner: { link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'}
  }

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const [user, setUser] = useState({
    name: 'Network',
    mail: '', 
    item: ''
  })

  const handleUser = async(item) => {
    setUser(item)
  }

  return (
  <>
    <Helmet>
      <title>{metadata.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
      <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
    </Helmet>

    <ContentFormat 
      
      name = {metadata.name}
      media = {{ size: 'xtra', show: false, data: <></> }}
      header = {{ 
        header: <>
          <Link to={`${asset.role}/home`}>
            <WebbIcon data={{ color: 'text-color-main', size: 'text-icon-sm' }}/>
          </Link>
        </>,       
        data: <> <WebbHeader /> </> ,
        footer: <><UserAvatar /></>,
      }}
      sidebar = {{ 
        header: <><p className="text-normal text-bold m-0">{metadata.name}</p></>,       
        data: <>
          {/* <NetworkSearchModule /> */}
          <NetworkListSmallModule user={handleUser}/>
        </> ,
        footer: <>...</>,
      }}      

      content = {{ 
        header: <>
          <p className="m-0">{user?.name || 'Network'}</p>
        </>,       
        data: <>
          <div className={user.name == 'Network' ? '' : 'd-none'}>
            <p>Please select a user</p>
          </div>
          
          <div className={user.name !== 'Network' ? '' : 'd-none'}>
            <p>Digital assets for {user.name}</p>
          </div>
          <NetworkViewCreatorModule item={user.item}/>
          <WebbDividerMedium />
          <WebbDividerMedium />
          <WebbDividerMedium />
          <WebbDividerMedium />
          <WebbDividerMedium />
          <WebbDividerMedium />
        </>
      }}
      actionbar = {{ 
        header: <>Your Actions</>,       
        data: <>
          <NetworkActionsModule />
          <WebbDividerSmall />


        </> ,
        footer: <></>,
      }} 
    
    ></ContentFormat>


  </>
  )
}