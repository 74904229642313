// assets
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { NumberFormat } from "../../services/srvc-utilities";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoaderMedium from "../webx/webb-loader-md";
import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbModuleInfo from "../webx/webb-module-info";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

// import { AssetList } from "../../services/srvc-assets-realm";
import list from "../../data.static/data-network.json";
import { CredentialsListUser, CredentialsListUserMembers } from "../../services/srvc-credentials-realm";
import { TeamContactDetails } from "../../services/srvc-users-creators-realm";

const media = {
  link: "https://img.freepik.com/free-vector/abstract-glowing-particles-background-blue-red-color_1017-26575.jpg?w=826",
};

// https://img.freepik.com/premium-vector/abstract-background-with-blur-bokeh-light-effect-modern-colorful-circular-blur-light-backdrop-vector-illustration_515038-7325.jpg?w=996
// https://img.freepik.com/premium-vector/abstract-background-with-blur-bokeh-light-effect-modern-colorful-circular-blur-light-backdrop-vector-illustration_515038-9990.jpg?w=996
// https://img.freepik.com/premium-vector/abstract-background-with-blur-bokeh-light-effect_515038-13016.jpg?w=996
// https://img.freepik.com/premium-vector/abstract-background-with-blur-bokeh-light-effect_515038-10766.jpg?w=996
// https://img.freepik.com/premium-vector/abstract-background-with-blur-bokeh-light-effect-modern-colorful-circular-blur-light-backdrop-vector-illustration_515038-966.jpg?w=996

export default function NetworkViewCreatorModule(props) {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();
  const { id } = useParams();

  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [detail, setDetail] = useState([]);

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        const result = await TeamContactDetails({
          data: {
            user: props.item,
            team: asset.item,
          },
          service: asset?.creds?.service,client:asset?.creds?.client,secret:asset?.creds?.secret
        });

        if (result) {
          setDetail(result?.data);
        }

        setLoader(false);
      };
      if (props.item !== "") {
        fetchData();
      }
    } else {
    }
  }, [props.item]);

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        const result = await CredentialsListUser({
          data: {
            creator:asset.item,
            user: props.item,
          },
          service: asset?.creds?.service,
          client:asset?.creds?.client,
          secret:asset?.creds?.secret
        });

        if (result) {
          setCurrentItems(result?.data?.list);
        }

        setLoader(false);
      };
      if (props.item !== "") {
        fetchData();
      }
    } else {
    }
  }, [props.item]);

  const handleClick = async (item) => {
    navigate(`/${asset.role}/network/${item}`);
  };

  if (loader)
    return (
      <>
        <div
          className="p-3 back-color-wite rounded-xd border align-middle"
          style={{}}
        >
          <span className="align-middle text-lead">
            <i className="bx bxs-info-circle text-color-wait"></i>
          </span>
          <span className="ms-1 text-color-tone">Please Wait...</span>
        </div>
      </>
    );

  return (
    <>
      <div className="mb-3">
        {props.item != "" && detail != [] && (
          <div className=" my-2  mbv-4">
            <div className="bg-white my-2  p-3 border rounded">
              <div className="d-flex  gap-4  justify-content-between">
                <p>Name</p>
                <p
                  style={{
                    minWidth: "50px",
                    marginLeft: "40px",
                    wordWrap: "break-word",
                  }}
                >
                  {detail?.name || ""}
                </p>
              </div>
              <div className="d-flex  gap-4  justify-content-between">
                <p>Email</p>
                <p
                  style={{
                    minWidth: "50px",
                    marginLeft: "40px",
                    wordWrap: "break-word",
                  }}
                >
                  {detail?.mail || ""}
                </p>
              </div>{" "}
              <div className="d-flex  gap-4  justify-content-between">
                <p>Mobile</p>
                <p
                  style={{
                    minWidth: "50px",
                    marginLeft: "40px",
                    wordWrap: "break-word",
                  }}
                >
                  {detail?.mmid || ""}
                </p>
              </div>
            </div>
          </div>
        )}

        {/* options */}
        <div className="row row-cols-1 row-cols-md-4 g-3 mt-4">
          {currentItems   &&
            currentItems.map((item, i) => (
              <div className={` m-0 mb-3 ${item.creator.name=="******"?"d-none":""}`} style={{width:"33%"}} key={i}>
                  <div
                key={item.item}
                className="bg-white border rounded position-relative mb-3"
                style={{ marginBottom: "20px", height:"285px"}}
              >

                <div  style={{
                    width: "100%",
                    height: "150px",
                    borderTopLeftRadius: "0.375rem",
                    borderTopRightRadius: "0.375rem",
                    objectFit:"cover"
                  }}>
                <img
                  src={item?.media?.link ||""}
                  className="border img-fluid"
                  style={{
                    width: "100%",
                    height: "150px",
                    borderTopLeftRadius: "0.375rem",
                    borderTopRightRadius: "0.375rem",
                    objectFit:"cover"
                  }}
                  alt="img"
                />
                </div>
                <p
                  className="px-3 pb-0 mt-1 fw-bold"
                  style={{ fontSize: "20px", color: "slateblue",height:"25px",display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  WebkitLineClamp: 1, }}
                >
                  {item?.meta?.name||"No data found...."}
                </p>
                <p
                  className="px-3 m-0 mb-2"
                  style={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    WebkitLineClamp: 2,
                    height:"45px"
                  }}
                >
                  {item?.meta?.memo||"No data found...."}
                </p>
                <p className="px-3 text-primary m-0 mt-2"  
                 style={{ display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  WebkitLineClamp: 1, }}
                >{item?.creator?.name||""}</p>
              </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
