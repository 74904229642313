import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import { DashboardTransfersSummary, TransfersGraphStatistics } from "../../services/srvc-transfers-funds-realm";

import LineChart from "../webx/dashboard-linechart";

const array = [
  {
    name: "Total",
    memo: "",
    icon: "bx bx-sort-alt-2",
    number: "",
    ticker: "***",
    rate: "",
    active: true,
  },
  {
    name: "Success",
    memo: "",
    icon: "bx bx-check-double",
    number: "",
    ticker: "***",
    rate: "",
    active: true,
  },
  {
    name: "Pending",
    memo: "",
    icon: "bx bx-radio-circle-marked",
    number: "",
    ticker: "***",
    rate: "",
    active: true,
  },
  {
    name: "Failed",
    memo: "",
    icon: "bx bx-x",
    number: "",
    ticker: "***",
    rate: "",
    active: true,
  },
  {
    name: "",
    memo: "",
    icon: "bx bx-user-circle",
    number: "",
    ticker: "***",
    rate: "",
    active: false,
  },
  {
    name: "",
    memo: "",
    icon: "bx bx-user-circle",
    number: "",
    ticker: "***",
    rate: "",
    active: false,
  },
];

export default function TeamTransferStatisticsModule() {
  const navigate = useNavigate();
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState();
  const [currentItems,setCurrentItems]=useState([])
  const [monthList,setMonthList] =useState([])


  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
      var res = await TransfersGraphStatistics({
        data: { 
          creator: asset.item,
          fromDate: "2024-01-16"  ,
          // toDate: "2023-01-01",
          status: "success",
        },
        service: asset?.creds?.service,
        client:asset?.creds?.client,
        secret:asset?.creds?.secret
      });
      if (res) {
        // console.log(res);
        setCurrentItems(res?.data);
        setMonthList(res?.data?.map(item =>item.month))
      }
     

    };

    fetchData();
  }, []);


  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);
        var res = await DashboardTransfersSummary({ data: { user: asset.item },service: asset?.creds?.service,client:asset?.creds?.client,secret:asset?.creds?.secret
        });
        // const result = listactions.filter(x => x.user.includes(asset.role))
        // setData(result)
        for (let i = 0; i < array.length; i++) {
          if (array[i]["name"].toLocaleLowerCase() === "total") {
            array[i] = { ...array[i], number: res?.data?.transaction?.total };
          }
          if (array[i]["name"].toLocaleLowerCase() === "success") {
            array[i] = { ...array[i], number: res?.data?.transaction?.success };
          }
          if (array[i]["name"].toLocaleLowerCase() === "pending") {
            array[i] = { ...array[i], number: res?.data?.transaction?.pending };
          }
          if (array[i]["name"].toLocaleLowerCase() === "failed") {
            array[i] = { ...array[i], number: res?.data?.transaction?.failed };
          }
        }

        setData(array);

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);

  const transfersData = {
    labels : ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
    // labels: [...monthList],
    datasets: [
      {
        label: "Total",
        data: [...currentItems.map(item => item?.number)],
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
        fill: "origin", 
        backgroundColor: "rgba(75, 192, 192, 0.2)", 
      },
    ],
  };

  const handleClick = (live, link) => {
    // if (live) navigate(`/${asset.role}/${link}`)
  };

  if (loader)
    return (
      <div className="text-center">
        <div className="spinner-border text-secondary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  return (
    <>
      <div className="rounded border">
        <div className="row row-cols-4 row-cols-md-4 g-2">
          {data && data.map((item, i) => item.active ? (
                <div className="col" key={i}>
                  <div
                    className={`back-color-wite rounded-xd p-3 w-100 h-100 m-0 p-0`}
                  >
                    <div className="d-flex">
                      <div className="">
                        <p className="text-lead text-bold m-0">{item.number || "0"}</p>
                        <p className="text-mini text-uppercase m-0">{item.name}</p>
                      </div>
                      <div className="ms-auto d-none">
                        <i
                          className={`text-icon-md text-color-tint ${item.icon}`}
                        ></i>
                      </div>
                    </div>

                    <div className="mb-2"></div>

                  </div>
                </div>
              ) : (
                ""
              )
            )}
        </div>
        
        <div className="border-bottom"></div>
        <WebbDividerSmall />

        <div className="p-2" style={{ height: "330px" }}>
          {currentItems.length > 0 && <LineChart data={transfersData} /> }
        </div>
      </div>
    </>
  );
}
