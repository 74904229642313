import axios from "axios";
import services from '../data.services/data-services-main.json'
import { decrypt, encrypt } from "./srvc-encr-node";

const base = (services.data.find(x => x.code == 'offers')).link

// -----------------

export const IndividualOfferCreate = async (item) => {
  
  const basx = base + '/assets/offer/create/individual';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item.client
  }
  const datx = {data: item.data, srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC}
    const payload = await encrypt(datx,item.secret)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}