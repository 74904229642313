import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import {  TeamsContactsGraphStatistics  } from "../../services/srvc-users-creators-realm";

import LineChart from "../webx/dashboard-linechart";

export default function TeamUserStatisticsModule() {
  const navigate = useNavigate();
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [loader, setLoader] = useState(false);
  const [data, setData] = useState();
  const [currentItems,setCurrentItems]=useState([])
  const [monthList,setMonthList] =useState([])


  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
      var res = await TeamsContactsGraphStatistics({
        data: { 
          team: asset.item,
        },
        service: asset?.creds?.service,
        client:asset?.creds?.client,
        secret:asset?.creds?.secret
      });
      if (res) {
        // console.log(res);
        setCurrentItems(res?.data);
        setMonthList(res?.data?.map(item =>item.month))
      }
     
      setLoader(false);

    };

    fetchData();
  }, []);


  const transfersData = {
    labels : ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
    // labels: [...monthList],
    datasets: [
      {
        label: "Total",
        // data:["11","42","33","40","75","63","27","18","59","10","71","13"],
        data: [...currentItems.map(item => item?.number)],
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
        fill: "origin", 
        backgroundColor: "rgba(75, 192, 192, 0.2)", 
      },
    ],
  };

  const handleClick = (live, link) => {
    // if (live) navigate(`/${asset.role}/${link}`)
  };

  if (loader)
    return (
      <div className="text-center">
        <div className="spinner-border text-secondary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  return (
    <>
      <div className="">
        <WebbDividerSmall />
        <div style={{ height: "400px" }}>
          {currentItems.length > 0 && <LineChart data={transfersData} /> }
        </div>
      </div>
    </>
  );
}
