// init
import axios from "axios";
import services from '../data.services/data-services-main.json'
import { encrypt, decrypt } from "./srvc-encr-node";

const base = (services.data.find(x => x.code == 'transfers.orders')).link

// -----------------

export const paymentOrderCreates = async (item) => {
  
  const basx = base + '/order/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item.client
  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)


  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }   
   } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const paymentOrderSave = async (item) => {
  
    const basx = base + '/order/save';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": item.client
    }
    const datx = { data: item.data, srvc: item.service }
    const payload = await encrypt(datx,item.secret)
  
  
    var result;
    try {
      result = await axios.post(basx, {payload}, { headers: head })
      const data = await decrypt(result.data.data,item.secret)
      return { code: result.status, stat: result.data.stat, data }   
     } catch (error) {
      return { code: error.response.status, ...error.response.data }
    }
  }

  export const paymentOrderVerify = async (item) => {
  
    const basx = base + '/order/verify';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": item.client
    }
    const datx = { data: item.data, srvc: item.service }
    const payload = await encrypt(datx,item.secret)
  
  
    var result;
    try {
      result = await axios.post(basx, {payload}, { headers: head })
      const data = await decrypt(result.data.data,item.secret)
      return { code: result.status, stat: result.data.stat, data }   
     } catch (error) {
      return { code: error.response.status, ...error.response.data }
    }
  }