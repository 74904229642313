// user account
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { NumberFormat } from "../../services/srvc-utilities";

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbIcon from "../webx/webb-icon";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import { AccountsBaseBalance } from "../../services/srvc-accounts-realm";


export default function AccountsBalanceBaseModule () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  
  const [loader, setLoader] = useState(true);

  const [balance, setBalance] = useState({number: '000000', ticker: '******'})
  const [data, setData] = useState()

  useEffect( () => {

    if (asset){
      const fetchData = async() => {
        setLoader(true);
        const result = await AccountsBaseBalance({data: {user: asset.item}, service: asset?.creds?.service,client:asset?.creds?.client,secret:asset?.creds?.secret})
        // console.log (result) 

        if (result.stat) {
          setBalance(result.data.balance)
          setData(result.data)
        }

        setLoader(false);
      }
      fetchData()
    } else {}
  },[]);

  // if (loader) return <WebbLoaderSmall/>


  return (
  <>
    {/* info */}

    {/* data */}
    <div className="back-color-wite p-3 rounded border">
      <p className="text-small m-0">Account Balance</p>
      <p className="m-0">
        <span className="text-lead text-bold">{NumberFormat(balance?.number/1000000 ||'0','', '2')}</span>
        <span className="ms-1"></span>
        <span className="text-small">{balance?.ticker || '***'}</span>
      </p>
    </div>

  </>

  )
}