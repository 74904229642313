// assets
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import WebbDividerSmall from '../webx/webb-divider-sm'
import WebbDividerMedium from '../webx/webb-divider-md'
import WebbLoaderMedium from '../webx/webb-loader-md'
import WebbLoaderSmall from '../webx/webb-loader-sm'
import WebbModuleInfo from '../webx/webb-module-info'
import FormNeeded from '../webx/form-needed'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import { UserAccountCreate } from '../../services/srvc-user-realm'
import { TeamContactCreate } from '../../services/srvc-users-creators-realm'

const list = ['user', 'business']

export default function NetworkMemberAddModule () {
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()

  const [loader, setLoader] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)

  const [form, setForm] = useState(false)
  const [memo, setMemo] = useState('')

  const [data, setData] = useState({
    name: null,
    mail: null,
    mobile: null
  })

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [])

  // useEffect for form validation
  useEffect(() => {
    setForm(false)
    if (data.name !== '' && data.mail !== '') setForm(true)
  }, [data])

  const isValidMobile = (mobile) => {
    // Regular expression for mobile number validation
    const mobileRegex = /^\d{10}$/;
    return mobileRegex.test(mobile);
  };


  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };



  const handleSubmit = async () => {

    if (isValidEmail(data.mail) && isValidMobile(data.mobile) && data.name) {

      setLoader(true)
      setSubmit(true)
      setMemo('Please wait...')
  
      const datx = {
        team: asset.item,
        user: {
          name: data.name,
          mail: data.mail,
          mobile: data.mobile,
          active: true,
          self: false
        },
        role: 'member'
      }
      //team contact create func.
      const result = await TeamContactCreate({ data: datx,service: asset?.creds?.service,client:asset?.creds?.client,secret:asset?.creds?.secret })
      // console.log(result)
  
        if (result.stat) {
          alert('User Created')
          setDone(true)
          navigate(-1)
        } else {
          alert('User Not Created')
          setDone(false)
          navigate(-1)

        }
  
      setLoader(false)

    } else {
      if (!isValidEmail(data.mail)) {
        alert("Please enter a valid email address.");
      } else if (!isValidMobile(data.mobile)) {
        alert("Please enter a valid 10-digit mobile number.");
      } else {
        alert("Please fill out all fields correctly.");
      }
    }
  
  
  }

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val })
  }

  


  // if (loader) return <></>

  return (
    <>
      {/* info */}
      <div className='mx-3'>
        <h2 className='text-normal m-0'>{'Grow Your Network'}</h2>

        <WebbDividerSmall />
      </div>

      <div
        className={
          submit
            ? 'back-color-wite p-3 rounded-xd'
            : 'back-color-wite p-3 rounded-xd'
        }
      >
        <WebbDividerSmall />
        <div className={''}>
          <div className='mb-3'>
            <label className='form-label text-small'>
              Name <FormNeeded />
            </label>
            <input
              type='text'
              className='form-control height-md'
              style={{ fontSize: '0.9rem', height: '2.7rem' }}
              value={data.name}
              onChange={({ target }) => {
                handleChange('name', target.value)
              }}
              disabled={loader || submit}
              placeholder=''
            ></input>
          </div>

          <div className='mb-3'>
            <label className='form-label text-small'>
              Email <FormNeeded />
            </label>
            <input
              type='text'
              className='form-control height-md'
              style={{ fontSize: '0.9rem', height: '2.7rem' }}
              value={data.mail}
              onChange={({ target }) => {
                handleChange('mail', target.value)
              }}
              disabled={loader || submit}
              placeholder=''
            ></input>
                {data.mail !== null && !isValidEmail(data.mail) && (
              <div className="mt-2 text-small text-danger">
                Please enter a valid email address.
              </div>
            )}

          </div>

          <div className='mb-3'>
            <label className='form-label text-small'>Mobile </label>
            <input
              type='number'
              className='form-control height-md'
              style={{ fontSize: '0.9rem', height: '2.7rem' }}
              value={data.mobile}
              onChange={({ target }) => {
                handleChange('mobile', target.value)
              }}
              disabled={loader || submit}
              placeholder=''
            ></input>
            {data.mobile !== null && !isValidMobile(data.mobile) && (
            <div className="mt-2 text-small text-danger">
             Please enter a valid 10-digit mobile number.
              </div>
    )}
          </div>
        </div>

        {/* <WebbDividerMedium /> */}
        {/* <div className=''>
          <p className='m-0'>{memo}</p>
        </div> */}

        <div className={submit ? 'd-none' : ''}>
          <WebbDividerMedium />
          <div className='d-flex justify-content-between'>
            <button
              className={`btn btn-light border back-color-wite rounded-xx text-small`}
              type='button'
              disabled={loader || submit}
              onClick={() => {
                navigate(-1)
              }}
            >
              {loader ? 'Please Wait...' : 'Cancel'}
            </button>

            <button
              className={`btn btn-primary border-none rounded-xx text-small`}
              type='button'
              disabled={!form || loader || submit}
              onClick={() => {
                handleSubmit()
              }}
            >
              {loader ? 'Please Wait...' : 'Continue'}
            </button>
          </div>
        </div>

        <WebbDividerSmall />
      </div>
    </>
  )
}
